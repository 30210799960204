import { UploadFileEntityResponse } from "../../graphql/strapi-cms";

export enum AudioSequenceElementType {
  Begin = "begin",
  Done = "done",
  Movement = "movement",
  Breath = "breath",
  Coaching = "coaching",
  GenericCoaching = "genericCoaching",
  Hold = "hold",
  Intro = "intro",
  Prescription = "prescription",
  Positioning = "positioning"
}

export enum VideoSequenceElementType {
  ExerciseIntroduction = "exercise-introduction",
  ExerciseSetup = "exercise-setup",
  ExercisePositioning = "exercise-positioning",
  ExerciseLoop = "exercise-loop",
  ExerciseRest = "exercise-rest",
  ExerciseSwitchSides = "exercise-switch-sides",
  WorkoutTransition = "workout-transition",
  WorkoutEnd = "workout-end",
}

export type VideoSequenceElementMetadata = {
  sets?: number;
  loops?: number;
  reps?: number;
  holdDuration?: number;
  restDuration?: number;
  slug?: string;
  name?: string;
  set?: number;
};

export type VideoSequenceElement = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
  type: VideoSequenceElementType;
  durationInFrames: number;
  offsetInFrames: number;
  audioFiles?: string[];
  videoFiles?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata?: VideoSequenceElementMetadata;
};

export type VideoSequence = {
  elements: VideoSequenceElement[];
  durationInFrames: number;
  offsetInFrames: number;
  fps: number;
};

export type AudioSequenceElement = {
  type: AudioSequenceElementType;
  audioClip: UploadFileEntityResponse;
  durationInFrames: number;
  startFromFrame: number;
};

export type AudioSequence = {
  elements: AudioSequenceElement[];
  durationInFrames: number;
  loopDurationInFrames?: number;
};

export interface WorkoutTimelineExercise {
  type: "Exercise";
  sets: number;
  reps: number;
  holdDuration: number;
  restDuration: number;
}

export interface WorkoutTimelineTransition {
  type: "Transition";
}

export interface WorkoutTimelineEnd {
  type: "Workout End";
}

export interface WorkoutTimelineItem {
  component:
    | WorkoutTimelineExercise
    | WorkoutTimelineTransition
    | WorkoutTimelineEnd;
  slug: string;
  startsAt: number;
  endsAt: number;
}

export interface WorkoutMetadata {
  slug: string;
  duration: number;
  timeline: WorkoutTimelineItem[];
}

;
    var _a, _b;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_b = (_a = __webpack_module__.hot.data) === null || _a === void 0 ? void 0 : _a.prevExports) !== null && _b !== void 0 ? _b : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
