import { SCENE_AUDIO_DELAY } from "../../../globals";
import {
  ExerciseSequence,
  UploadFileEntityResponse
} from "../../../graphql/strapi-cms";
import { secondsToFrames } from "../../../utils/duration";
import { type AudioSequence, AudioSequenceElementType } from "../../types";
import { AudioSequencer } from ".";

export default class ExerciseIntroAudioSequencer extends AudioSequencer {
  protected runningDuration = 0;

  constructor(
    private exercise: ExerciseSequence,
    private prescriptionAudio?: UploadFileEntityResponse
  ) {
    super();

    this.exercise = exercise;
    this.prescriptionAudio = prescriptionAudio;
  }

  /**
   * sequence
   *
   * The format for sequencing the audio for an exercise introduction is as follows:
   *
   * - [exercise name]
   * - [1 second]
   * - [prescription]
   * - [2 seconds]
   *
   * @returns
   */
  async sequence() {
    const sequence: AudioSequence = {
      elements: [],
      durationInFrames: 0
    };

    const { introduction } = this.exercise;

    if (introduction.audioClip.data?.attributes?.url) {
      const durationInFrames = secondsToFrames(
        await this.getAudioDuration(
          introduction.audioClip.data?.attributes?.url
        )
      );

      sequence.elements.push({
        startFromFrame: SCENE_AUDIO_DELAY,
        durationInFrames,
        audioClip: introduction.audioClip,
        type: AudioSequenceElementType.Intro
      });

      this.runningDuration += SCENE_AUDIO_DELAY + durationInFrames;
    }

    if (this.prescriptionAudio?.data?.attributes?.url) {
      const durationInFrames = secondsToFrames(
        await this.getAudioDuration(
          this.prescriptionAudio?.data?.attributes?.url
        )
      );

      sequence.elements.push({
        startFromFrame: (this.runningDuration += secondsToFrames(1)),
        durationInFrames: durationInFrames + secondsToFrames(1),
        audioClip: this.prescriptionAudio,
        type: AudioSequenceElementType.Prescription
      });

      this.runningDuration += durationInFrames + secondsToFrames(1);
    }

    sequence.durationInFrames = this.runningDuration + secondsToFrames(2);

    return sequence;
  }
}


;
    var _a, _b;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_b = (_a = __webpack_module__.hot.data) === null || _a === void 0 ? void 0 : _a.prevExports) !== null && _b !== void 0 ? _b : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
