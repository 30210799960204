import {
  ExerciseIntroduction,
  ExerciseLoop,
  ExercisePositioning,
  ExerciseRest,
  ExerciseSetup,
  ExerciseSwitchSides
} from "../components/exercise";
import { SceneEnd } from "../components/scene/SceneEnd";
import { WorkoutTransition } from "../components/workout";
import {
  VideoSequenceElement,
  VideoSequenceElementType
} from "../services/types";

const renderVideoElement = (element: VideoSequenceElement) => {
  switch (element.type) {
    case VideoSequenceElementType.ExerciseIntroduction:
      return <ExerciseIntroduction {...element.props} />;
    case VideoSequenceElementType.ExerciseSetup:
      return <ExerciseSetup {...element.props} />;
    case VideoSequenceElementType.ExercisePositioning:
      return (
        <ExercisePositioning
          durationInFrames={element.durationInFrames}
          {...element.props}
        />
      );
    case VideoSequenceElementType.ExerciseLoop:
      return (
        <ExerciseLoop
          durationInFrames={element.durationInFrames}
          {...element.props}
        />
      );
    case VideoSequenceElementType.ExerciseSwitchSides:
      return <ExerciseSwitchSides {...element.props} />;
    case VideoSequenceElementType.ExerciseRest:
      return <ExerciseRest {...element.props} />;
    case VideoSequenceElementType.WorkoutTransition:
      return <WorkoutTransition {...element.props} />;
    case VideoSequenceElementType.WorkoutEnd:
      return <SceneEnd />;
    default:
      return null;
  }
};

export { renderVideoElement };


;
    var _a, _b;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_b = (_a = __webpack_module__.hot.data) === null || _a === void 0 ? void 0 : _a.prevExports) !== null && _b !== void 0 ? _b : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
