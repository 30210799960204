import {
  DEFAULT_SWITCH_SIDES_DURATION,
  SCENE_TRANSITION_OFFSET
} from "../../../globals";
import {
  type ComponentExercisePositioning,
  type ComponentExerciseVideo,
  Enum_Componentexercisepositioning_Type,
  Enum_Componentexercisevideo_Direction,
  type ExerciseSequence,
  type WorkoutMedia
} from "../../../graphql/strapi-cms";
import { secondsToFrames } from "../../../utils/duration";
import { resolveRestAudio, resolveSwitchSideAudio } from "../../audio";
import { ExerciseLoopAudioSequencer } from "../../sequencing/audio";
import {
  AudioSequenceElement,
  AudioSequenceElementType,
  type VideoSequenceElement,
  VideoSequenceElementType
} from "../../types";
import ExercisePositioningAudioSequencer from "../audio/exercise-positioning-audio-sequencer";
export default abstract class ExerciseLoopBuilder {
  protected coachingUsed: { [key: string]: AudioSequenceElement[] } = {};

  constructor(
    protected exercise: ExerciseSequence,
    protected workoutMedia: WorkoutMedia,
    protected sets: number,
    protected reps: number,
    protected restDuration: number,
    protected holdDuration: number
  ) {
    this.exercise = exercise;
    this.workoutMedia = workoutMedia;
    this.sets = sets;
    this.reps = reps;
    this.restDuration = restDuration;
    this.holdDuration = holdDuration;
  }

  protected isHoldExercise() {
    const { baseHold } = this.exercise;

    return Number(baseHold) > 0;
  }

  protected getNumberOfVideoLoops() {
    const { baseHold = 10, baseReps = 5 } = this.exercise;

    if (this.isHoldExercise()) {
      return Math.round(this.holdDuration / Number(baseHold));
    } else if (!this.isHoldExercise() && Number(baseReps) > 0) {
      return Math.round(this.reps / Number(baseReps));
    }

    return 1;
  }

  protected setArray() {
    return Array.from(Array(this.sets).keys());
  }

  protected getIntoPosition(video: ComponentExerciseVideo) {
    return video.positioning?.find(
      (p) => p?.type === Enum_Componentexercisepositioning_Type.In
    );
  }

  protected getOutOfPosition(video: ComponentExerciseVideo) {
    return video.positioning?.find(
      (p) => p?.type === Enum_Componentexercisepositioning_Type.Out
    );
  }

  protected async buildPositioning(
    position: ComponentExercisePositioning,
    direction: Enum_Componentexercisevideo_Direction,
    set: number
  ) {
    if (!position.duration)
      throw new Error(
        `Positioning for ${this.exercise.slug} duration is missing`
      );

    const audioSequencer = new ExercisePositioningAudioSequencer(
      position,
      direction,
      this.workoutMedia
    );

    const isLastSet = set === this.sets - 1;
    const isFirstSet = set === 0;

    const audioSequence = await audioSequencer.sequence(
      set,
      isFirstSet,
      isLastSet
    );

    return {
      type: VideoSequenceElementType.ExercisePositioning,
      props: {
        ...position,
        audio: audioSequence
      },
      durationInFrames: secondsToFrames(position.duration),
      offsetInFrames: SCENE_TRANSITION_OFFSET
    };
  }

  protected async buildExercise(
    set: number,
    video: ComponentExerciseVideo,
    loops: number
  ): Promise<VideoSequenceElement> {
    const videoDuration = secondsToFrames(video.duration);

    if (!videoDuration)
      throw new Error(`Video for ${this.exercise.slug} duration is missing`);

    const audioSequencer = new ExerciseLoopAudioSequencer(
      video,
      set,
      videoDuration * loops,
      video.audioSequence,
      this.workoutMedia,
      this.holdDuration,
      set === this.sets - 1,
      this.coachingUsed[video.direction as string] ?? [],
      this.sets
    );

    const audioSequence = await audioSequencer.sequence();

    if (!this.coachingUsed[video.direction as string])
      this.coachingUsed[video.direction as string] = [];

    this.coachingUsed[video.direction as string].push(
      ...audioSequence.elements.filter(
        (e) =>
          e.type === AudioSequenceElementType.Coaching ||
          e.type === AudioSequenceElementType.GenericCoaching
      )
    );

    return {
      type: VideoSequenceElementType.ExerciseLoop,
      props: {
        ...video,
        sequenceName: this.exercise.name as string,
        isLastSet: set === this.sets - 1,
        holdDuration: this.holdDuration,
        set: set + 1,
        workoutMedia: this.workoutMedia,
        loops: loops,
        sequencedAudio: audioSequence
      },
      durationInFrames: videoDuration * loops,
      offsetInFrames: SCENE_TRANSITION_OFFSET,
      metadata: {
        set,
        loops,
        reps: this.reps
      }
    };
  }

  protected buildRest(): VideoSequenceElement {
    return {
      type: VideoSequenceElementType.ExerciseRest,
      props: {
        audioClip: resolveRestAudio(this.restDuration, this.workoutMedia)
      },
      durationInFrames: secondsToFrames(this.restDuration),
      offsetInFrames: SCENE_TRANSITION_OFFSET
    };
  }

  protected buildSwitchSides(): VideoSequenceElement {
    return {
      type: VideoSequenceElementType.ExerciseSwitchSides,
      props: {
        audioClip: resolveSwitchSideAudio(this.workoutMedia)
      },
      durationInFrames: secondsToFrames(
        this.workoutMedia.switchSidesDuration || DEFAULT_SWITCH_SIDES_DURATION
      ),
      offsetInFrames: SCENE_TRANSITION_OFFSET
    };
  }
}


;
    var _a, _b;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_b = (_a = __webpack_module__.hot.data) === null || _a === void 0 ? void 0 : _a.prevExports) !== null && _b !== void 0 ? _b : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
